import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator, Flex, Heading, Menu, MenuItem, Divider, View } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

import { Hub } from 'aws-amplify';

export function Layout() {
  const { route, signOut, user } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();

  const [title, setTitle] = React.useState('Welcome');

  function logOut() {
    signOut();
    navigate('/login');
  }

  Hub.listen('auth', (data) => {
    // console.log(data.payload);
    switch (data.payload.event) {
      case 'signIn':
          console.log('user signed in');
          break;
      case 'signUp':
          console.log('user signed up');
          if (user && user.attributes) {
            if (user.attributes["custom:formation_id"] === undefined) {
              resetUserFormationId();
            }
          }
          break;
      case 'signOut':
          console.log('user signed out');
          break;
      case 'signIn_failure':
          console.log('user sign in failed');
          break;
      case 'configured':
          console.log('the Auth module is configured');
          break;
      default: 
          console.log('default case');
          break;
    }
  });

  async function resetUserFormationId() {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      'custom:formation_id': "-1"
    });
  }

  React.useEffect(() => {
    if (user && user.attributes) {
      if (user.attributes["custom:formation_id"] === undefined) {
        resetUserFormationId();
      }
    }
    // console.log(user);
  }, [user]);

  return (
    <>
      <Menu
        menuAlign="start"
      >
        <MenuItem onClick={() => {navigate('/'); setTitle('Home');}}>
          Home
        </MenuItem>
        <MenuItem onClick={() => {navigate('/user-team'); setTitle('Squadra');}}>
          Squadra
        </MenuItem>
        <MenuItem onClick={() => {navigate('/classification'); setTitle('Classifica');}}>
          Classifica
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => {navigate('/rules'); setTitle('Regolamento');}}>
          Regolamento
        </MenuItem>
        <MenuItem onClick={() => {navigate('/bonus-malus'); setTitle('Bonus e Malus');}}>
          Bonus/Malus
        </MenuItem>
        <MenuItem onClick={() => {navigate('/quotations'); setTitle('Quotazioni');}}>
          Quotazioni
        </MenuItem>
        <Divider />
        {route !== 'authenticated' ? (
          <MenuItem onClick={() => {navigate('/login'); setTitle('Login');}}>Login</MenuItem>
        ) : (
          <MenuItem onClick={() => {logOut(); setTitle('Welcome');}}>Logout</MenuItem>
        )}
      </Menu>
      <Flex direction="row" alignItems="center" justifyContent="center">
        <View>
          {route === 'authenticated' ? 'You are logged in as ' : 'Please Login!'}
          <View>
            {user?.attributes?.email}
          </View>
          
        </View>
      </Flex>

      <Outlet />
    </>
  );
}