import { Heading, View, Flex, Text } from '@aws-amplify/ui-react';

export function Bonus({bonus}) {

  const _bonus = JSON.parse(bonus);
  return (
    <View padding="xs" direction="row">
        <Flex direction="column">
            <Heading padding="medium" level={6}>{'BONUS: ' + _bonus.name}</Heading>
            <Text>{'DESCRIZIONE: ' + _bonus.description}</Text>
            <Text>{'PUNTI: ' + _bonus.points}</Text>
        </Flex>
    </View>
  );
}