// components/Protected.js
import { useAuthenticator, Heading, View, Collection, Card, Flex, Text } from '@aws-amplify/ui-react';

import React, {useState} from 'react';

import { API } from "aws-amplify";
import { listAthlets } from '../graphql/queries';
import { WeaponToString } from '../lib/weapon-to-string';
import { Bonus } from './bonus';

export function Quotations() {
  const { route } = useAuthenticator((context) => [context.route]);

  const message =
    route === 'authenticated' ? 'Atleti e Quotazioni' : 'Loading...';
    
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [athlets, setAthlets] = useState([]);

  React.useEffect(() => {
    setFetched(false);
    setLoading(true);
    getAthlets();
  }, []);

  const getAthlets = async () => {
    try {
      const _athlets = await API.graphql({
        query: listAthlets,
      });
      if (_athlets?.data?.listAthlets?.items) {
        setAthlets(_athlets.data.listAthlets.items);
        setLoading(false);
        setFetched(true);
      } else {
        setAthlets();
        setLoading(false);
        setFetched(false);
      }
      // console.log(_athlets?.data?.listAthlets?.items);
    } catch (error) {
      throw new Error(error);
    }
  }

  return (<View>
    <Heading level={3}>{message}</Heading>
    {loading && !fetched && (
      <Heading level={4}>{"Loading"}</Heading>
    )}
    {!loading && fetched && (
      <Collection
        items={athlets}
        type="list"
        direction="column"
        gap="20px"
        wrap="nowrap"
        style={{alignItems: 'center'}}
      >
        {(item, index) => (
          <Card
            key={index}
            borderRadius="medium"
            maxWidth="30rem"
            variation="outlined"
          >
            <View padding="xs">
              <Flex direction="column">
                <Flex direction="row">
                  <Heading level={6} padding="medium" style={{textAlign: 'center', alignSelf: "center"}}>{item.name + " " + item.surname}</Heading>
                  <Text alignSelf="center">{"Prezzo: " + (item.price !== null ? item.price : 5)} </Text>
                </Flex>
                <Flex direction="row">
                  <Text>{"Arma: " + WeaponToString(item.weapon)} </Text>
                  <Text>{"Rione: " + item.ward} </Text>
                </Flex>
                {item.bonus && (
                  <Bonus bonus={item.bonus} />
                )}
                {item.malus && (
                  <Bonus bonus={item.malus} />
                )}
              </Flex>
            </View>
          </Card>
        )}
      </Collection>
    )}
  </View>);
}