import { View, Heading, Text, Image, Divider } from '@aws-amplify/ui-react';
import React from 'react';

import logo from '../assets/logo.jpeg';

export function Home() {

  React.useEffect(() => {

    const script = document.createElement("script");
    script.src="https://cdn.iubenda.com/iubenda.js"; 
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <View>
      <Heading level={3}>
        Benvenuto al Fanta Disfida! 
      </Heading>

      <Text> Stiamo lavorando per il FantaDisfida 2024! </Text>

      <Image src={logo} />

      <Text fontSize="large" fontWeight="semibold">
        {"Fatto con <3"}
      </Text>
      <View>
        <a href="https://www.iubenda.com/privacy-policy/26107181" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
      </View>
    </View>
  );
}