// components/Protected.js
import React, {useState} from 'react';

import { useAuthenticator, Heading, View, Button, Text, Card, Collection, Flex, Alert } from '@aws-amplify/ui-react';

import { API } from "aws-amplify";
import { createFormation } from '../graphql/mutations';
import { listWards, listAthlets, getFormation, getAthlet } from '../graphql/queries';
import { Auth } from 'aws-amplify';

// TODO:
// - controllo crediti sulla selezione dell'atleta
// - controllo selezione atleti dello stesso rione 

export function UserTeam() {
  const { route, user } = useAuthenticator((context) => [context.route]);

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);

  const [remaingCoins, setRemaingCoins] = useState(100);
  const [nextRemaingCoins, setNextRemaingCoins] = useState(100);

  const [wards, setWards] = useState([]);
  const [selectedWard, setSelectedWard] = useState();

  const [arrowMans, setArrowMans] = useState([]);
  const [selectedArrowMan, setSelectedArrowMan] = useState();

  const [crossbawMans, setCrossbawMans] = useState([]);
  const [selectedCrossbowMan, setSelectedCrossbowMan] = useState();

  const [pickMans, setPickMans] = useState([]);
  const [selectedPickMan, setSelectedPickMan] = useState();

  const [flailMans, setFlailMans] = useState([]);
  const [selectedFlailMan, setSelectedFlailMan] = useState();

  const [userFormation, setUserFormation] = useState();

  const [showAlertDate, setShowAlertDate] = useState();
  const [alertDateMessage, setAlertDateMessage] = useState();

  const [showAlert, setShowAlert] = useState();
  const [alertMessage, setAlertMessage] = useState();

  React.useEffect(() => {
    setFetched(false);
    setLoading(true);
  }, []);

  // da capire cosa deve fare e quando
  React.useEffect(() => {
    handleLoadPage();
  }, []);

  const handleLoadPage = async() => {
    try {
      const _user = await Auth.currentAuthenticatedUser();
      console.log(_user.attributes);
      if (_user && _user.attributes && (_user.attributes["custom:formation_id"] === undefined || _user.attributes["custom:formation_id"] === "-1")) {
        if (!checkDate()) {
          setShowAlertDate(true);
          setAlertDateMessage("Ci dispiace, ma la creazione della squadra è terminata alle 21:00 del 24 Luglio!");  
        } else {
          setStep(1);
        }
      } else if (_user && _user.attributes) {
        getUserFormation();
      }
    } catch(error) {
      console.log(error);
      throw(error);
    }
  }

  React.useEffect(() => {
    if (step === 1) {
      getWards();
    } else if (step === 2) {
      getAthlets();
    } else if (step === 7) {
      getUserFormation();
    }
  }, [step]);

  const updateNextRemainingCoins = (price) => {
    if (remaingCoins - price < 0) {
      setShowAlert(true);
      setAlertMessage("Crediti insufficenti!");
      return false;
    }

    setNextRemaingCoins(remaingCoins - price);
    return true;
  }

  const checkAthletEnabled = (athlet) => {
    if (step === 2) {
      return true;
    }
    if (step === 3) {
      return true;
    }
    if (step === 4) {
      if (selectedArrowMan.ward === selectedCrossbowMan.ward && athlet.ward === selectedArrowMan.ward)
      {
        // console.log({selectedArrowMan, selectedCrossbowMan, athlet})
        return false;
      }
      return true;
    }
    if (step === 5) {
      if ((selectedArrowMan.ward === selectedCrossbowMan.ward && athlet.ward === selectedArrowMan.ward) ||
        (selectedArrowMan.ward === selectedPickMan.ward && athlet.ward === selectedArrowMan.ward) || 
        (selectedCrossbowMan.ward === selectedPickMan.ward && athlet.ward === selectedCrossbowMan.ward))
      {
        return false;
      }
      return true;
    }

    return true;
  }

  const getUserFormation = async () => { 
    try {
      // console.log('getUserFormation');
      const _user = await Auth.currentAuthenticatedUser();
      const _userFormation = await API.graphql({
        query: getFormation,
        variables: { id: _user.attributes["custom:formation_id"] }
      });
      // console.log(_userFormation);
      // console.log('step ' + step);
      if (step === 0) {
        setLoading(false);
        setFetched(true);
        // Qui dovrei scaricare le singole info dei tiratori
        if (_userFormation.data.getFormation === null) {
          setStep(1);
          return;
        }
        // console.log('fetch arrow', _userFormation.data.getFormation.arrow_man);
        const _arrow = await API.graphql({
          query: getAthlet,
          variables: { id: _userFormation.data.getFormation.arrow_man }
        });
        const _crossbaw = await API.graphql({
          query: getAthlet,
          variables: { id: _userFormation.data.getFormation.crossbaw_man }
        });
        const _pick = await API.graphql({
          query: getAthlet,
          variables: { id: _userFormation.data.getFormation.pick_man }
        });
        const _flail = await API.graphql({
          query: getAthlet,
          variables: { id: _userFormation.data.getFormation.flail_man }
        });
        setUserFormation({
          ward: _userFormation.data.getFormation.ward,
          arrow: _arrow.data.getAthlet,
          crossbaw: _crossbaw.data.getAthlet,
          pick: _pick.data.getAthlet,
          flail: _flail.data.getAthlet,
        });
      } else if (step === 7) {
        // Qui ho già le info dalle selezioni, quindi rimostro quello che ho
        setLoading(false);
        setFetched(true);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }

  const getWards = async () => {
    try {
      const _wards = await API.graphql({
        query: listWards,
      });
      setWards(_wards.data.listWards.items);
      setLoading(false);
      setFetched(true);
      // console.log(_wards.data.listWards.items);
    } catch (error) {
      throw new Error(error);
    }
  }

  const getAthlets = async () => {
    try {
      const _athlets = await API.graphql({
        query: listAthlets,
      });
      const _arrowMans = [];
      const _crossbawMans = [];
      const _pickMans = [];
      const _flailMans = [];
      for (const athlet of _athlets.data.listAthlets.items) {
        if (athlet) {
          if (athlet.weapon === "ARROW") {
            _arrowMans.push(athlet);
          } else if (athlet.weapon === "CROSSBOW") {
            _crossbawMans.push(athlet);
          } else if (athlet.weapon === "SPEAR") {
            _pickMans.push(athlet);
          } else if (athlet.weapon === "FLAIL") {
            _flailMans.push(athlet);
          }
        }
      }
      setArrowMans(_arrowMans);
      setCrossbawMans(_crossbawMans);
      setPickMans(_pickMans);
      setFlailMans(_flailMans);
      setLoading(false);
      setFetched(true);
    } catch (error) {
      throw new Error(error);
    }
  }

  const sendFormation = async () => {
    return;
    try {
      // console.log('sendFormation');
      const newFormation = await API.graphql({
        query: createFormation,
        variables: {
            input: {
            "arrow_man": selectedArrowMan.id,
            "crossbaw_man": selectedCrossbowMan.id,
            "pick_man": selectedPickMan.id,
            "flail_man": selectedFlailMan.id,
            "ward": selectedWard.ward_id,
            "user_id": user.attributes.sub,
            "user_name": user.attributes.email,
            "total_credits": 100 - remaingCoins,
            "confirmation_date": new Date().toISOString(),
          }
        }
      });
      updateUserFormationId(newFormation.data.createFormation.id);
      
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  const updateUserFormationId = async (id) => {
    try {
      // console.log('updateUserFormationId');
      const updated = await Auth.updateUserAttributes(user, {
        'custom:formation_id': id,
      });
      // console.log(updated);
      setStep(7);
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  const checkDate = () => {
    const date = new Date();
    date.toLocaleString('en-US', {
      hour12: false,
    });
    var dd = date.getDate();
    var mm = date.getMonth() + 1; //January is 0!
    var yyyy = date.getFullYear();
    var hours = date.getHours();

    console.log({date, yyyy, mm, dd, hours});

    console.log('sto cazz');
    if (yyyy >= 2023 && mm >= 7) {
      console.log('Patatine');
      return false;
    }

    if (((dd >= 24 && hours >= 21) || dd > 24)) {
      return false;
    }

    return true;
  }
  
  const message =
    route === 'authenticated' ? 'La mia squadra' : 'Loading...';
  
  const stepMessage = 
    loading ? "Loading..." :
    step === 0 && fetched ? "Riepilogo" : 
    step === 0 && !fetched ? "Boh" :
    step === 1 ? "Scegli un Rione" : 
    step === 2 ? "Scegli un Arciere" : 
    step === 3 ? "Scegli un Balestriere" :
    step === 4 ? "Scegli un Lanciere" :
    step === 5 ? "Scegli un Mazziere" :
    step === 6 ? "Riepilogo" :
    step === 7 ? "Congratulazioni! Ecco la tua squadra!" : "Errore";

  return (
    <View> 
      <Heading level={3}>{message}</Heading>
      {step !== 0 && (
        <View>
          <Text fontSize="large" fontWeight="semibold">
            Hai tempo fino alle 21:00 del 24/07/2023 per formare la tua squadra!
          </Text>
          <Text fontSize="large" fontWeight="semibold">
            Una volta formata, la tua squadra non potrà più essere modificata. Scegli saggiamente!
          </Text>
        </View>
      )}
      {step !== 0 && (
        <View>
          <Text>{'Crescioli rimanenti: ' + remaingCoins}</Text>
          <Text>{'Previsione Crescioli rimanenti: ' + nextRemaingCoins}</Text>
        </View>
      )}
      <Heading level={4}>{stepMessage}</Heading> 
      
      {step === 0 && userFormation && (
        <View style={{flex: 1}}>
          <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Rione: ' + userFormation.ward}</Text>
          <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Arciere: ' + userFormation.arrow.name + ' ' + userFormation.arrow.surname}</Text>
          <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Balestriere: ' + userFormation.crossbaw.name + ' ' + userFormation.crossbaw.surname}</Text>
          <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Lanciere: ' + userFormation.pick.name + ' ' + userFormation.pick.surname}</Text>
          <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Mazziere: ' + userFormation.flail.name + ' ' + userFormation.flail.surname}</Text>
        </View>
      )}
      {step === 1 && (
        <View style={{flex: 1}}>
          {!loading && fetched && (
            <Collection
              items={wards}
              type="list"
              direction="column"
              gap="20px"
              wrap="nowrap"
              margin="1rem"
            >
              {(item, index) => (
                <Card
                  key={index}
                  borderRadius="medium"
                  maxWidth="30rem"
                  minWidth="15rem"
                  variation="outlined"
                  alignSelf="center"
                  backgroundColor= { item === selectedWard ? '#FF0000' : '#FFFFFF' }
                  onClick={() => setSelectedWard(item)}
                >
                  <View padding="xs" alignItems="center" justifyContent="center" alignSelf="center">
                    <Text level={6} padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{item.name}</Text>
                  </View>
                </Card>
              )}
            </Collection>
          )}
          <Button loadingText=""
                  onClick={() => {
                    setStep(2);
                  }}
                  disabled={selectedWard === undefined}
                  ariaLabel="">
            Avanti
          </Button>
        </View>
      )}
      {step === 2 && (
        <View style={{flex: 1}}>
          {!loading && fetched && (
            <Collection
              items={arrowMans}
              type="list"
              direction="column"
              gap="20px"
              wrap="nowrap"
              margin="1rem"
            >
              {(item, index) => (
                <Card
                  key={index}
                  borderRadius="medium"
                  maxWidth="30rem"
                  minWidth="15rem"
                  variation="outlined"
                  alignSelf="center"
                  backgroundColor= { item === selectedArrowMan ? '#FF0000' : '#FFFFFF' }
                  onClick={() => {
                    const price = item.price !== null ? item.price : 1;
                    if (updateNextRemainingCoins(price)) {
                      setSelectedArrowMan(item); 
                    }
                  }}
                >
                  <View padding="xs" >
                    <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{item.name + ' ' + item.surname}</Text>
                    <Text>{'Costo: ' + (item.price !== null ? item.price : '1')}</Text>
                  </View>
                </Card>
              )}
            </Collection>
          )}
          <Button loadingText=""
                  onClick={() => {
                    setStep(3);
                    const price = selectedArrowMan.price !== null ? selectedArrowMan.price : 1;
                    setRemaingCoins(remaingCoins - price);
                  }}
                  disabled={selectedArrowMan === undefined}
                  ariaLabel="">
            Avanti
          </Button>
        </View>
      )}
      {step === 3 && (
        <View style={{flex: 1}}>
          {!loading && fetched && (
            <Collection
              items={crossbawMans}
              type="list"
              direction="column"
              gap="20px"
              wrap="nowrap"
              margin="1rem"
            >
              {(item, index) => (
                <Card
                  key={index}
                  borderRadius="medium"
                  maxWidth="30rem"
                  minWidth="15rem"
                  variation="outlined"
                  alignSelf="center"
                  backgroundColor= { item === selectedCrossbowMan ? '#FF0000' : '#FFFFFF' }
                  onClick={() => { 
                    const price = item.price !== null ? item.price : 1;
                    if (updateNextRemainingCoins(price)) {
                      setSelectedCrossbowMan(item); 
                    }
                  } }
                >
                  <View padding="xs" alignItems="center" justifyContent="center" alignSelf="center">
                    <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{item.name + ' ' + item.surname}</Text>
                    <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Costo: ' + item.price !== null ? item.price : 1}</Text>
                  </View>
                </Card>
              )}
            </Collection>
          )}
          <Button loadingText=""
                  onClick={() => {
                    setStep(4);
                    const price = selectedCrossbowMan.price !== null ? selectedCrossbowMan.price : 1;
                    setRemaingCoins(remaingCoins - price);
                  }}
                  disabled={selectedCrossbowMan === undefined}
                  ariaLabel="">
            Avanti
          </Button>
        </View>
      )}
      {step === 4 && (
        <View style={{flex: 1}}>
          {!loading && fetched && (
            <Collection
              items={pickMans}
              type="list"
              direction="column"
              gap="20px"
              wrap="nowrap"
              margin="1rem"
            >
              {(item, index) => (
                <Card
                  key={index}
                  borderRadius="medium"
                  maxWidth="30rem"
                  minWidth="15rem"
                  variation="outlined"
                  alignSelf="center"
                  backgroundColor= { item === selectedPickMan ? '#FF0000' : !checkAthletEnabled(item) ? '#CCCCCC' : '#FFFFFF' }
                  isDisabled={!checkAthletEnabled(item)}
                  onClick={() => { 
                    if (!checkAthletEnabled(item)) { 
                      return;
                    }
                    const price = item.price !== null ? item.price : 1;
                    if (updateNextRemainingCoins(price)) {
                      setSelectedPickMan(item); 
                    }
                  } }
                >
                  <View padding="xs" alignItems="center" justifyContent="center" alignSelf="center">
                    <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{item.name + ' ' + item.surname}</Text>
                    <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Costo: ' + item.price !== null ? item.price : 1}</Text>
                  </View>
                </Card>
              )}
            </Collection>
          )}
          <Button loadingText=""
                  onClick={() => {
                    setStep(5);
                    const price = selectedPickMan.price !== null ? selectedPickMan.price : 1;
                    setRemaingCoins(remaingCoins - price);
                  }}
                  disabled={selectedPickMan === undefined}
                  ariaLabel="">
            Avanti
          </Button>
        </View>
      )}
      {step === 5 && (
        <View style={{flex: 1}}>
          {!loading && fetched && (
            <Collection
              items={flailMans}
              type="list"
              direction="column"
              gap="20px"
              wrap="nowrap"
              margin="1rem"
            >
              {(item, index) => (
                <Card
                  key={index}
                  borderRadius="medium"
                  maxWidth="30rem"
                  minWidth="15rem"
                  variation="outlined"
                  alignSelf="center"
                  backgroundColor= { item === selectedFlailMan ? '#FF0000' : !checkAthletEnabled(item) ? '#CCCCCC' : '#FFFFFF' }
                  isDisabled={!checkAthletEnabled(item)}
                  onClick={() => { 
                    if (!checkAthletEnabled(item)) { 
                      return;
                    }
                    const price = item.price !== null ? item.price : 1;
                    if (updateNextRemainingCoins(price)) {
                      setSelectedFlailMan(item); 
                    }
                  }}
                >
                  <View padding="xs" alignItems="center" justifyContent="center" alignSelf="center">
                    <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{item.name + ' ' + item.surname}</Text>
                    <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Costo: ' + item.price !== null ? item.price : 1}</Text>
                  </View>
                </Card>
              )}
            </Collection>
          )}
          <Button loadingText=""
                  onClick={() => {
                    setStep(6);
                    const price = selectedFlailMan.price !== null ? selectedFlailMan.price : 1;
                    setRemaingCoins(remaingCoins - price);
                  }}
                  disabled={selectedFlailMan === undefined}
                  ariaLabel="">
            Avanti
          </Button>
        </View>
      )}
      {step === 6 && (
        <View style={{flex: 1}}>
          <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Rione: ' + selectedWard.name}</Text>
          <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Arciere: ' + selectedArrowMan.name + ' ' + selectedArrowMan.surname}</Text>
          <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Balestriere: ' + selectedCrossbowMan.name + ' ' + selectedCrossbowMan.surname}</Text>
          <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Lanciere: ' + selectedPickMan.name + ' ' + selectedPickMan.surname}</Text>
          <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Mazziere: ' + selectedFlailMan.name + ' ' + selectedFlailMan.surname}</Text>
                    
          <Button loadingText=""
                  onClick={() => {
                    return;
                    sendFormation();
                  }}
                  ariaLabel="">
            Salva!
          </Button>
          <Button loadingText=""
                  onClick={() => {
                    setStep(0);
                    setSelectedArrowMan();
                    setSelectedCrossbowMan();
                    setSelectedFlailMan();
                    setSelectedPickMan();
                  }}
                  ariaLabel="">
            Annulla!
          </Button>
        </View>
      )}
       {step === 7 && (
        <View style={{flex: 1}}>
          <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Rione: ' + selectedWard.name}</Text>
          <Text padding="0" style={{textAlign:  'center', alignSelf: "center"}}>{'Arciere: ' + selectedArrowMan.name + ' ' + selectedArrowMan.surname}</Text>
          <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Balestriere: ' + selectedCrossbowMan.name + ' ' + selectedCrossbowMan.surname}</Text>
          <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Lanciere: ' + selectedPickMan.name + ' ' + selectedPickMan.surname}</Text>
          <Text padding="0" style={{textAlign: 'center', alignSelf: "center"}}>{'Mazziere: ' + selectedFlailMan.name + ' ' + selectedFlailMan.surname}</Text>
        </View>
      )}
      {showAlertDate && (
        <Alert
          isDismissible={false}
          hasIcon={true}
          heading="Warning"
          variation="warning"
          >
          {alertDateMessage}
        </Alert>
      )}
      {showAlert && (
        <Alert
          isDismissible={true}
          hasIcon={true}
          heading="Warning"
          variation="warning"
          >
          {alertMessage}
        </Alert>
      )}
    </View>
  );
}