import { useAuthenticator, Heading, View, Collection, Card, Flex, Text } from '@aws-amplify/ui-react';
import React, {useState} from 'react';

import { API } from "aws-amplify";
import { listBonuses } from '../graphql/queries';

export function BonusMalus() {
  const { route } = useAuthenticator((context) => [context.route]);

  const message =
    route === 'authenticated' ? 'Bonus e Malus' : 'Loading...';

  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [bonuses, setBonuses] = useState([]);
  const [maluses, setMaluses] = useState([]);

  React.useEffect(() => {
    setFetched(false);
    setLoading(true);
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      console.log("getBonuses requested");
      const _data = await API.graphql({
        query: listBonuses,
      });
      // console.log(_bonus);
      if (_data?.data?.listBonuses?.items) {
        let _maluses = [];
        let _bonuses = [];
        for (const d of _data.data.listBonuses.items) {
          if (d) {
            if (d.is_bonus === true) {
              _bonuses.push(d);
            } else {
              _maluses.push(d);
            }
          }
        }
        setBonuses(_bonuses);
        setMaluses(_maluses);
        setLoading(false);
        setFetched(true);
      } else {
        setBonuses([]);
        setMaluses([]);
        setLoading(false);
        setFetched(false);
      }
      // console.log(_data?.data?.listBonuses?.items);
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }

    return (
    <View>
      <Heading level={3}>{message}</Heading>
      {!loading && fetched && (
      <View style={{ alignItems: 'center',
        justifyContent: 'center'}}>
        <Heading level={3} style={{textAlign: 'center', alignSelf: "center"}}>{"Bonus"}</Heading>
        <Collection
          items={bonuses}
          type="list"
          direction="column"
          style={{alignItems: 'center'}}
          gap="20px"
          wrap="nowrap"
        >
          {(item, index) => (
            <Card
              key={index}
              borderRadius="medium"
              maxWidth="30rem"
              variation="outlined"
            >
              <View padding="xs">
                <Flex direction="column" style={{textAlign: 'center', alignSelf: "center", gap: "0"}}>
                  <Heading level={4} style={{textAlign: 'center', alignSelf: "center"}}>{item.is_bonus ? "Bonus" : "Malus"}</Heading>
                  <Heading level={5} style={{textAlign: 'center', alignSelf: "center"}}>{"Nome: " + item.name}</Heading>
                  {(item.description !== null && item.description !== undefined) && (<Heading level={5} style={{textAlign: 'center', alignSelf: "center"}}>{"Descrizione: " + item.description}</Heading>)}
                  <Heading level={5} style={{textAlign: 'center', alignSelf: "center"}}>{"Punti: " + item.points}</Heading>
                </Flex>
              </View>
            </Card>
          )}
        </Collection>
        <Heading level={3} style={{textAlign: 'center', alignSelf: "center"}}>{"Malus"}</Heading>
        <Collection
          items={maluses}
          type="list"
          direction="column"
          style={{alignItems: 'center'}}
          gap="20px"
          wrap="nowrap"
        >
          {(item, index) => (
            <Card
              key={index}
              borderRadius="medium"
              maxWidth="30rem"
              variation="outlined"
            >
              <View padding="xs">
                <Flex direction="column" style={{textAlign: 'center', alignSelf: "center", gap: "0"}}>
                  <Heading level={4} style={{textAlign: 'center', alignSelf: "center"}}>{item.is_bonus === true ? "Bonus" : "Malus"}</Heading>
                  <Heading level={5} style={{textAlign: 'center', alignSelf: "center"}}>{"Nome: " + item.name}</Heading>
                  {(item.description !== null && item.description !== undefined) && (<Heading level={5} style={{textAlign: 'center', alignSelf: "center"}}>{"Descrizione: " + item.description}</Heading>)}
                  <Heading level={5} style={{textAlign: 'center', alignSelf: "center"}}>{"Punti: -" + item.points}</Heading>
                </Flex>
              </View>
            </Card>
          )}
        </Collection>
      </View>
    )}
    </View>);
}