// components/Protected.js
import { useAuthenticator, Heading, View, Collection, Card, Flex, Text } from '@aws-amplify/ui-react';
import React, {useState} from 'react';

import { Auth } from 'aws-amplify';

import { API } from "aws-amplify";
import { listWards, listAthlets, listFormations } from '../graphql/queries';

export function Classification() {
  const { route } = useAuthenticator((context) => [context.route]);

  const [userFormation, setUserFormation] = useState();
  const [formations, setFormations] = useState([]);

  const message =
    route === 'authenticated' ? 'Classifica' : 'Loading...';

  React.useEffect(() => {
    fetchAll();
  }, []);

  const fetchAll = async () => {
    // console.log('fetch all');
    try {
      const _formations = await API.graphql({
        query: listFormations,
      });
      const _wards = await API.graphql({
        query: listWards,
      });
      const _athlets = await API.graphql({
        query: listAthlets,
      });
      const _user = await Auth.currentAuthenticatedUser();

      const ws = _wards.data.listWards.items;
      const fs = _formations.data.listFormations.items;
      const as = _athlets.data.listAthlets.items
      
      // console.log(_user);
      // per ogni formazione devo calcolare il punteggio
      const formationsToShow = [];
      for (const f of fs) {
        let username = f.user_name.split('@')[0];
        if (username.length > 12) {
          username = username.substring(0, 12);
        }
        const uf = {
          id: f.id,
          points: 0,
          user: username,
          ward: undefined,
          arrow: undefined,
          crossbaw: undefined,
          pick: undefined,
          flail: undefined,
        }
        
        if (f.ward) {
          for (const ward of ws) {
            // console.log("FORMATION", {wp: ward, fw: f});
            if (ward.ward_id === f.ward) {
              uf.ward = ward.name;
              console.log(uf.ward);
              if (uf.ward === 'Torrione') {
                uf.points += 25;
              } else if (uf.ward === 'San Bernardino') {
                uf.points += 50;
              } else if (uf.ward === 'Croce') {
                uf.points -= 50;
              } else if (uf.ward === 'Sacramento') {
                uf.points += 0;
              } else {
                uf.points += ward.points !== undefined ? ward.points : 0;
              }
              break;
            }
          }
        }
        if (f.arrow_man) {
          for (const athlet of as) {
            if (athlet.id === f.arrow_man) {
              uf.arrow = athlet;
              uf.points += athlet.points !== undefined ? athlet.points : 0;
              break;
            }
          }
        }
        if (f.crossbaw_man) {
          for (const athlet of as) {
            if (athlet.id === f.crossbaw_man) {
              uf.crossbaw = athlet;
              uf.points += athlet.points !== undefined ? athlet.points : 0;
              break;
            }
          }
        }
        if (f.pick_man) {
          for (const athlet of as) {
            if (athlet.id === f.pick_man) {
              uf.pick = athlet;
              uf.points += athlet.points !== undefined ? athlet.points : 0;
              break;
            }
          }
        }
        if (f.flail_man) {
          for (const athlet of as) {
            if (athlet.id === f.flail_man) {
              uf.flail = athlet;
              uf.points += athlet.points !== undefined ? athlet.points : 0;
              break;
            }
          }
        }
        formationsToShow.push(uf);
      }
      // _84bcfeb0731f7b896a361f8f05303570

      formationsToShow.sort((a,b) => b.points - a.points);

      let actualPosition = 0;
      let lastPoints = 5000;
      for (const f of formationsToShow) {
        if (f.points < lastPoints) {
          lastPoints = f.points;
          actualPosition += 1;
        }
        f.pos = actualPosition;
        if (f.id === _user.attributes["custom:formation_id"]) {
          setUserFormation(f)
        }
      }

      setFormations(formationsToShow);
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }
  // TODO spostare la logica nel backend
  // per ogni formazione calcolo il punteggio in base al rione e gli atleti scelti 

  return (
    <View>
      <Heading level={3}>{message}</Heading>

      {userFormation !== undefined && (
        <View style={{ 
                alignItems: 'center'
              }}>
          <Heading level={4}>{"Il tuo piazzamento"}</Heading>
          <View style={{ 
                alignItems: 'center',
              }}>
              <View padding="xs">
                <Flex direction="column">
                  <Flex direction="row" alignSelf='center'>
                    <Text level={4} style={{textAlign: 'start', alignSelf: "start"}}>{"Pos: " + userFormation.pos}</Text>
                    <Text level={4} style={{textAlign: 'start', alignSelf: "start"}}>{"User: " + userFormation.user}</Text>
                    <Text level={4} style={{textAlign: 'end', alignSelf: "end"}}>{"Punti: " + userFormation.points}</Text>
                  </Flex>
                  <Text level={5} style={{textAlign: 'center', alignSelf: "center"}}>{"Formazione: " + userFormation.ward + ', ' + userFormation.arrow.surname + ', ' + userFormation.crossbaw.surname+ ', ' + userFormation.pick.surname + ', ' + userFormation.flail.surname}</Text>
                </Flex>
              </View>
          </View>
          <Heading level={4}>{"Classifica Generale"}</Heading>
        </View>
      )}
      <Collection
          items={formations}
          type="list"
          direction="column"
          style={{alignItems: 'center'}}
          gap="10px"
          wrap="nowrap"
        >
          {(item, index) => (
            <Card
              key={index}
              borderRadius="medium"
              maxWidth="30rem"
              variation="outlined"
            >
              <View padding="xs">
                <Flex direction="column">
                  <Flex direction="row">
                    <Text level={4} style={{textAlign: 'start', alignSelf: "start"}}>{"Pos: " + item.pos}</Text>
                    <Text level={4} style={{textAlign: 'start', alignSelf: "start"}}>{"User: " + item.user}</Text>
                    <Text level={4} style={{textAlign: 'end', alignSelf: "end"}}>{"Punti: " + item.points}</Text>
                  </Flex>
                  <Text level={5} style={{textAlign: 'center', alignSelf: "center"}}>{"Formazione: " + item.ward + ', ' + item.arrow.surname + ', ' + item.crossbaw.surname+ ', ' + item.pick.surname + ', ' + item.flail.surname}</Text>
                </Flex>
              </View>
            </Card>
          )}
        </Collection>
    </View>
  );
}