/* src/App.js */
import { Authenticator } from '@aws-amplify/ui-react';


import { RequireAuth } from './require-auth';
import { Login } from './components/login';
import { Home } from './components/home';
import { Layout } from './components/layout';
import { Classification } from './components/classification';
import { Quotations } from './components/quotations';
import { UserTeam } from './components/user-team';
import { Rules } from './components/rules';
import { BonusMalus } from './components/bonus-malus';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path="/user-team"
            element={
              <RequireAuth>
                <UserTeam />
              </RequireAuth>
            }
          />
          <Route
            path="/rules"
            element={
              <RequireAuth>
                <Rules />
              </RequireAuth>
            }
          />
          <Route
            path="/bonus-malus"
            element={
              <RequireAuth>
                <BonusMalus />
              </RequireAuth>
            }
          />
          <Route
            path="/classification"
            element={
              <RequireAuth>
                <Classification />
              </RequireAuth>
            }
          />
          <Route
            path="/quotations"
            element={
              <RequireAuth>
                <Quotations />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}

export default App;







/*
import React, { useEffect, useState } from 'react'
import { Amplify, API, graphqlOperation } from 'aws-amplify'
import { createTodo } from './graphql/mutations'
import { listTodos } from './graphql/queries'
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const initialState = { name: '', description: '' }

const App = ({ signOut, user }) => {
  const [formState, setFormState] = useState(initialState)
  const [todos, setTodos] = useState([])

  useEffect(() => {
    fetchTodos()
  }, [])

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
  }

  async function fetchTodos() {
    try {
      const todoData = await API.graphql(graphqlOperation(listTodos))
      const todos = todoData.data.listTodos.items
      setTodos(todos)
    } catch (err) { console.log('error fetching todos') }
  }

  async function addTodo() {
    try {
      if (!formState.name || !formState.description) return
      const todo = { ...formState }
      setTodos([...todos, todo])
      setFormState(initialState)
      await API.graphql(graphqlOperation(createTodo, {input: todo}))
    } catch (err) {
      console.log('error creating todo:', err)
    }
  }

  return (
    <div style={styles.container}>
      <Heading level={1}>Benvenuti al Fanta Disfida</Heading>

      <h2>Classifica</h2>
      <h2>Crea Squadra/ La mia squadra</h2>
      <h2>Regolamento</h2>
      <h2>Quotazioni</h2>

      <Button onClick={signOut} style={styles.button}>Sign out</Button>
    </div>
  )
}

const styles = {
  container: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  todo: {  marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

export default withAuthenticator(App);
*/