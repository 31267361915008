/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFormation = /* GraphQL */ `
  query GetFormation($id: ID!) {
    getFormation(id: $id) {
      id
      arrow_man
      crossbaw_man
      pick_man
      flail_man
      ward
      confirmation_date
      user_id
      user_name
      total_credits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFormations = /* GraphQL */ `
  query ListFormations(
    $filter: ModelFormationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        arrow_man
        crossbaw_man
        pick_man
        flail_man
        ward
        confirmation_date
        user_id
        user_name
        total_credits
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFormations = /* GraphQL */ `
  query SyncFormations(
    $filter: ModelFormationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFormations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        arrow_man
        crossbaw_man
        pick_man
        flail_man
        ward
        confirmation_date
        user_id
        total_credits
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBonus = /* GraphQL */ `
  query GetBonus($id: ID!) {
    getBonus(id: $id) {
      id
      name
      points
      is_bonus
      id_typology
      typology
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBonuses = /* GraphQL */ `
  query ListBonuses(
    $filter: ModelBonusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBonuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        points
        is_bonus
        id_typology
        typology
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBonuses = /* GraphQL */ `
  query SyncBonuses(
    $filter: ModelBonusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBonuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        points
        is_bonus
        id_typology
        typology
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAthlet = /* GraphQL */ `
  query GetAthlet($id: ID!) {
    getAthlet(id: $id) {
      id
      name
      surname
      ward
      age
      points
      characteristics
      previous_victories
      price
      quota_bonus_malus
      bonus
      malus
      weapon
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAthlets = /* GraphQL */ `
  query ListAthlets(
    $filter: ModelAthletFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAthlets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        surname
        ward
        age
        points
        characteristics
        previous_victories
        price
        quota_bonus_malus
        bonus
        malus
        weapon
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAthlets = /* GraphQL */ `
  query SyncAthlets(
    $filter: ModelAthletFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAthlets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        surname
        ward
        age
        points
        characteristics
        previous_victories
        price
        quota_bonus_malus
        bonus
        malus
        weapon
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWard = /* GraphQL */ `
  query GetWard($id: ID!) {
    getWard(id: $id) {
      id
      name
      previous_victories
      athlets
      logo
      symbol_weapon
      trand
      trip_advisor
      men_of_honor
      colors
      ward_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listWards = /* GraphQL */ `
  query ListWards(
    $filter: ModelWardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        previous_victories
        athlets
        logo
        symbol_weapon
        trand
        trip_advisor
        men_of_honor
        colors
        ward_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWards = /* GraphQL */ `
  query SyncWards(
    $filter: ModelWardFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        previous_victories
        athlets
        logo
        symbol_weapon
        trand
        trip_advisor
        men_of_honor
        colors
        ward_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      password
      nickname
      idSquadra
      formazione
      privacy
      registration_date
      credits
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        password
        nickname
        idSquadra
        formazione
        privacy
        registration_date
        credits
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        password
        nickname
        idSquadra
        formazione
        privacy
        registration_date
        credits
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
