import { useAuthenticator, Heading, View, Collection, Card, Flex, Text } from '@aws-amplify/ui-react';
import React, {useState} from 'react';

export function Rules() {
  const { route } = useAuthenticator((context) => [context.route]);

  const message =
    route === 'authenticated' ? 'Regolamento' : 'Loading...';

    return (
    <View>
      <Heading level={3}>{message}</Heading>
      <View style={{ alignItems: 'center',
        justifyContent: 'center'}}>
        <Text level={3} style={{textAlign: 'center', alignSelf: "center"}}>
        - Ogni giocatore ha a disposizione 100 crescioli
        </Text>
        <Text level={3} style={{textAlign: 'center', alignSelf: "center"}}>
        - E' obbligatorio schierare una squadra contenente 4 tiratori di armi differenti, di cui al massimo due dello stesso rione. Oltre ai tiratori, deve essere scelto un rione. Se il rione scelto vincerà la contesa, la squadra avrà 50 punti aggiuntivi ai punteggi dei tiratori.
        </Text>
        <Text level={3} style={{textAlign: 'center', alignSelf: "center"}}>- E' possibile iscrivere la squadra entro Luedì 24 Luglio 2023 alle ore 20:00.
        </Text>
        <Text level={3} style={{textAlign: 'center', alignSelf: "center"}}>
        - I bonus ed i malus dei tiratori verranno attribuiti in base a questo regolamento ed al giudizio soggettivo dei giudici, è un gioco e va considerato tale.
        </Text>
        <Text level={3} style={{textAlign: 'center', alignSelf: "center"}}>- L'assegnazione dei punti, dei bonus e dei malus vale per tutta la giornata del 24 e 25 Luglio, spareggi compresi.
        </Text>
        <Text level={3} style={{textAlign: 'center', alignSelf: "center"}}>
        - Nel caso un tiratore non si sia qualificato o non potrà partecipare alla gara, il tiratore otterrà 0 punti e non potrà usufruire di bonus e malus.
        </Text>
        <Text level={3} style={{textAlign: 'center', alignSelf: "center"}}>
        - Vince chi al termine della disfida in armi avrà totalizzato il maggior numero di punti. In caso di più squadre con lo stesso punteggio verranno considerate tutte vincitrici.
        </Text>
      </View>
    </View>);
}